export const Environment = {
    alpha: {
        "type": "alpha",
        "url": "dev.api.litmaps.com"
    },
    local: {
        "type": "local",
        "url": "localhost:8081"
    },
    production: {
        "type": "production",
        "url": "api.litmaps.com"
    }
}

export function environment(value) {
    console.log(value);
    switch (value) {
        case 'alpha':
            return Environment.alpha;
        case 'local':
            return Environment.local;
        default:
            return Environment.production;
    }
}