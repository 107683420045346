import SignInView from '../Views/SignInView';
import AppView from '../Views/AppView';
import ProfileView from '../Views/ProfileView';
import MetricsView from '../Views/MetricsView';
import ArticleView from '../Views/ArticleView';
import ArticleListView from '../Views/ArticleListView';
import RandomArticleListView from '../Views/RandomArticleListView';

export const APP = {
  component: AppView,
  exact: true,
  path: '/',
};

export const SIGN_IN = {
  component: SignInView,
  exact: true,
  path: '/signIn',
};

export const EDIT_PROFILE = {
  component: ProfileView,
  exact: true,
  path: '/profile',
};

export const METRICS = {
  component: MetricsView,
  exact: true,
  path: '/metrics',
};

export const ARTICLE = {
  component: ArticleView,
  exact: true,
  path: '/article/:id',
};

export const ARTICLE_LIST = {
  component: ArticleListView,
  exact: true,
  path: '/articles',
};

export const RANDOM_ARTICLE_LIST = {
  component: RandomArticleListView,
  exact: true,
  path: '/random-articles',
};

export const PUBLIC_ROUTES = [SIGN_IN];

export const PRIVATE_ROUTE = [
  APP,
  EDIT_PROFILE,
  METRICS,
  ARTICLE,
  ARTICLE_LIST,
  RANDOM_ARTICLE_LIST,
];
