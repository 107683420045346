import React from 'react';
import { fetchUserActivity, fetchUserCount } from '../Backend/Server';
import CollectionCard from '../UI/CollectionCard';
import NavBar from '../UI/NavBar';

class MetricsView extends React.Component {
    auUsers = new Map();
    usersWithKeywordSearchCount = new Map();
    twoDayNewUsers = new Map();
    articleCategorizationCount = 0;
    state = {
        au: 0,
        reachCount: 0,
        sevenDayCount: 0,
        thirtyDayCount: 0
    }

    updateAU(items) {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            this.auUsers.set(item.associatedUser, true);
        }
        console.log(this.auUsers);
        this.setState({au: this.auUsers.size});
    }

    // need to do API calls to fetch metrics
    componentDidMount() {
        let keywordForActive = {eventName: "keywordSearch", daysBeforeNow: "30"};
        let articleCatForActive = {eventName: "articlePutIntoCategory", daysBeforeNow: "30"};
        let networkExpansionForActive = {eventName: "networkExpansion", daysBeforeNow: "30"};
        let projectGraphViewedForActive = {eventName: "projectGraphViewed", daysBeforeNow: "30"};
        fetchUserActivity(keywordForActive).then(users => {
            this.updateAU(users.events)
        });
        fetchUserActivity(articleCatForActive).then(users => {
            this.updateAU(users.events)
            this.articleCategorizationCount = users.events.length;
        });
        fetchUserActivity(networkExpansionForActive).then(users => {
            this.updateAU(users.events)
        });
        fetchUserActivity(projectGraphViewedForActive).then(users => {
            this.updateAU(users.events)
        });

        let userCountLastSixMonths = {daysBeforeNow: "182"}
        fetchUserCount(userCountLastSixMonths).then(obj => {
            this.setState({reachCount: obj.users.length});
        })

        let userCountLastTwoDays = {daysBeforeNow: "2"}
        fetchUserCount(userCountLastTwoDays).then(obj => {
            for (let user of obj.users) {
                this.twoDayNewUsers.set(user.id, true);
            }
        })

        let keywordInLastTwo = {eventName: "keywordSearch", daysBeforeNow: "2"};
        fetchUserActivity(keywordInLastTwo).then(users => {
            for (let value of users.events) {
                if (this.usersWithKeywordSearchCount.has(value.associatedUser)) {
                    this.usersWithKeywordSearchCount.set(value.associatedUser, this.usersWithKeywordSearchCount.get(value.associatedUser) + 1)
                } else {
                    this.usersWithKeywordSearchCount.set(value.associatedUser, 1)
                }
            }
        }); 

        let loginInLastSeven = {eventName: "userLogin", daysBeforeNow: "7"};
        fetchUserActivity(loginInLastSeven).then(events => {
            let users = new Map();
            for (let i = 0; i < events.events.length; i++) {
                let item = events.events[i];
                users.set(item.associatedUser, true)
            }
            this.setState({sevenDayCount: users.size});
        });
        let loginInLastThirty = {eventName: "userLogin", daysBeforeNow: "30"};
        fetchUserActivity(loginInLastThirty).then(events => {
            let users = new Map();
            for (let i = 0; i < events.events.length; i++) {
                let item = events.events[i];
                users.set(item.associatedUser, true)
            }
            this.setState({thirtyDayCount: users.size});
        });
    }

    render() {
        let usersWithTwoOrMoreSearchesInLastTwoDays = 0;
        for (let [key, value] of this.usersWithKeywordSearchCount) {
            if (value >= 2 && this.twoDayNewUsers.has(key)) {
                usersWithTwoOrMoreSearchesInLastTwoDays += 1;
            }
        }
        
        let percentageActivation = (usersWithTwoOrMoreSearchesInLastTwoDays / this.twoDayNewUsers.size) * 100.0;
        if (Number.isNaN(percentageActivation)) {
            percentageActivation = 0;
        }

        let percentageEngagement = (this.articleCategorizationCount / this.state.au);
        if (Number.isNaN(percentageEngagement)) {
            percentageEngagement = 0;
        }

        const focusMetric = {
            value: this.state.au,
            title: "Monthly Active Users (MAU)",
            subtitle: "No. of Active Users of Litmaps in the last 30 days.",
            subtitle2: "Focus Metric",
            onClickUrl: "",
        }
        console.log(focusMetric.value);

        // Create lv1 items
        let items = [];
        items.push({
            value: this.state.reachCount,
            title: "No. of Litmaps Users in the last 6 months",
            subtitle: "How many people used Litmaps recently?",
            subtitle2: "Reach Metric",
            onClickUrl: "",
        })
        items.push({
            value: `${percentageActivation}%`,
            title: "New Users that perform 2 searches in their first 48 hours of use",
            subtitle: "Foundational step that primes a new user to become an active user.",
            subtitle2: "Activation Metric",
            onClickUrl: "",
        })
        items.push({
            value: `${percentageEngagement}`,
            title: "Articles put in a category of a project per monthly active user.",
            subtitle: "A measure of engagement with the product.",
            subtitle2: "Engagement Metric",
            onClickUrl: "",
        })
        items.push({
            value: this.state.sevenDayCount,
            title: "7-day Retention",
            subtitle: "Litmaps Users that return within 7 days.",
            subtitle2: "Retention Metric",
            onClickUrl: "",
        })
        items.push({
            value: this.state.thirtyDayCount,
            title: "30-day Retention",
            subtitle: "Litmaps Users that return within 30 days.",
            subtitle2: "Retention Metric",
            onClickUrl: "",
        })

        return <div>
            <NavBar/>
            <div style={{
                margin: "25px"
            }}>
                <h1>Metrics Dashboard</h1>
                <h3>This is a collection of metrics to build understanding of user activity. More details in the <a href="https://www.notion.so/litmaps/User-Analytics-Framework-653351c6c01c4fbdad5f39610967a59b">Notion</a></h3>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <CollectionCard 
                        value={focusMetric.value}
                        title={focusMetric.title}
                        subtitle={focusMetric.subtitle} 
                        subtitle2={focusMetric.subtitle2}
                        onClickUrl={""}
                    />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}>
                    {items.map((value, _) => {
                        return <CollectionCard
                            value={value.value}
                            title={value.title}
                            subtitle={value.subtitle} 
                            subtitle2={value.subtitle2}
                            onClickUrl={""}
                        />
                    })}
                </div>
            </div>
        </div>
    }
}

export default MetricsView;