
class Callbacks {

	constructor(){
		this.addListener = this.addListener.bind(this);
		this.removeListener = this.removeListener.bind(this);
		this.callbacks = { _general: {} };
	}

	// Convenience handler for addListener
	// Supports string (eventName, callback)
	// Supports object ({ eventName1: callback1, eventName2: callback2 })
	on(event, callback){
		switch(typeof(event)){
			case "string":
				return this.addListener(event, callback);
			case "object":
				let keys = Object.keys(event);
				let all = keys.map(k => this.addListener(k, event[k]));
				return () => all.forEach(a => a());
			default:
				throw new Error(`Unhandled Callbacks.on signature arg1 type ${typeof(event)}`);
		}
	}

	addListener(eventName, callback){
		if(!this.callbacks._general[eventName]){
			this.callbacks._general[eventName] = [];
		}
		this.callbacks._general[eventName].push(callback);
		return () => this.removeListener(eventName, callback);
	}

	removeListener(eventName, callback){
		if(this.callbacks._general[eventName]){
			let index = this.callbacks._general[eventName].indexOf(callback);
			if(index !== -1){
				this.callbacks._general[eventName].splice(index, 1);
			} else {
				console.log(`Cannot remove listener from ${eventName} - callback doesn't exist.`);
			}
		} else {
			console.log(`Cannot remove listener from ${eventName} - no callbacks are defined.`);
		}
	}

	addSet(setName, callbacks){
		this.callbacks[setName] = callbacks;
		return () => this.removeSet(setName);
	}

	removeSet(setName){
		delete this.callbacks[setName];
	}

	dispatch(callbackName, _args){
		let args = Array.from(arguments);
		args = args.slice(1, args.length);
		for(let set in this.callbacks){
			if(this.callbacks[set]){
				if(this.callbacks[set][callbackName]){
					if(Array.isArray(this.callbacks[set][callbackName])){
						this.callbacks[set][callbackName].forEach(cb => cb(...args));
					} else {
						this.callbacks[set][callbackName](...args);
					}
				}
			}
		}
	}

}

export default Callbacks;