import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { APP } from "../Routes/RouteConfig";
import {Input} from "../UI/index";
import { signIn, getIdToken } from "./../Backend/Auth";
import {connect} from 'react-redux';

const mapStateToProps = (state) => ({
    authState: state.user.signedIn
});

const SignInView = (props) => {
	let history = useHistory();
	const [email, setEmail] = useState(process.env.NODE_ENV === "development" ? "axton@litmaps.co" : "")
	const [password, setPassword] = useState(process.env.NODE_ENV === "development" ? "123456" : "")
	const [requiresVerification, setRequiresVerification] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.authState === true) {
			history.push(APP.path)
		}
	})

    const handleSignIn = (() => {
		setLoading(true);
		setRequiresVerification(false);
		setError(null);
		signIn(email, password)
		.then(async result => {
			console.log("Signed in, hitting /user...", result);
			let token = await getIdToken();
			if(!token){
				throw new Error("Failed to find token.")
			} else {
				setLoading(false)
			}
		})
		.catch(error => {
			if(error.message === "user-not-confirmed"){
				setRequiresVerification(true);
			} else {
				console.log(error.message);
				setError(error);
			}
		}).finally(() => setLoading(false));
    })

	return <Fragment>
			<label for="email">Email</label>
			<Input type="email" id="email" name="email" value={email} placeholder="example@email.com" autocomplete="username" onChange={email => setEmail(email)} onSubmit={handleSignIn}/>
			
			<label for="password">Password</label>
			<Input type="password" id="password" name="password" value={password}  placeholder="Password" autocomplete="password" onChange={password => setPassword(password)} onSubmit={handleSignIn}/>
			
			<input type="submit" value="Sign in" onClick={() => {handleSignIn()}}/>
	</Fragment>
};

export default connect(mapStateToProps, null)(SignInView);