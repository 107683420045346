import * as React from "react";
import {PRIVATE_ROUTE, SIGN_IN} from "./RouteConfig";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
    authState: state.user.signedIn
});

const PrivateRoute = (props) => {
    if (props.authState === true) {
        console.log("Logged in")
        return (
            PRIVATE_ROUTE.map(item => {
                return (
                    <Route key={item.path}
                           component={item.component}
                           exact={item.exact}
                           path={item.path}
                    />
                    )
            })
        )
    } else {
        console.log("Logged out")
      return <Redirect to={SIGN_IN.path}/>
    }
};

export default connect(mapStateToProps, null)(PrivateRoute)