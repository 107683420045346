/**
 * Configuration for aws-amplify
 */

export const amplifyConfig = {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID}`,
    AdvancedSecurityDataCollectionFlag: false,
};

// amplify Hosted UI configuration
export const oauthConfig = {
    domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
    scope: ['openid','email','profile'],
    redirectSignIn: `${process.env.REACT_APP_COGNITO_REDIRECT_URL}`,
    redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT_URL}`,
    // Note that REFRESH token will only be generated when the responseType is code
    // See https://aws-amplify.github.io/docs/js/authentication#manual-setup
    responseType: 'code'
};