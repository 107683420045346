import React from "react";

class Mouseable extends React.Component {

	state = {
		hovered: false,
		held: false
	}

	render(){
		let { disabled, onRef, onMouseEnter, onMouseLeave, onHovered, onClick, onHeld } = this.props;
		let props = Object.assign({}, this.props);
		let { hovered, held } = this.state;
		props.style = Object.assign({},
			props.style,
			hovered ? props.hoveredStyle : null,
			held ? props.heldStyle : null,
		);
		delete(props.disabled);
		delete(props.heldStyle);
		delete(props.hoveredStyle);
		delete(props.onHeld);
		delete(props.onHovered);
		delete(props.onRef);
		delete(props.onMouseEnter);
		delete(props.onMouseLeave);
		delete(props.onClick);
		return <div
			ref={onRef}
			onMouseEnter={evt => {
				if(disabled){
					return;
				}
				if(onMouseEnter){
					onMouseEnter(evt);
				}
				if(onHovered && !this.state.hovered){
					onHovered(true);
				}
				this.setState({hovered: true});
			}}
			onMouseDown={() => {
				if(disabled){
					return;
				}
				if(onHeld && !this.state.held){
					onHeld(true);
				}
				this.setState({held: true});
			}}
			onMouseUp={() => {
				if(disabled){
					return;
				}
				if(onHeld && this.state.held){
					onHeld(false);
				}
				this.setState({held: false});
			}}
			onMouseLeave={evt => {
				if(onMouseLeave){
					onMouseLeave(evt);
				}
				this.setState({hovered: false, held: false});
				if(disabled){
					return;
				}
				if(onHeld && this.state.held){
					onHeld(false);
				}
				if(onHovered && this.state.hovered){
					onHovered(false);
				}
			}}
			onClick={evt => {
				if(disabled){
					return;
				}
				this.setState({held: false});
				if(onClick){
					onClick(evt);
				}
			}}
			{...props}
		/>
	}
}

export default Mouseable;