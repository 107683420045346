import React from 'react';
import "./CollectionCard.css";

const CollectionCard = (props) => {
    function handleClick(url) {
        window.location.href = `${url}`;
    }

    return (
        <div style={{
            margin: "10px"
        }} onClick={() => {handleClick(props.onClickUrl)}}>
            <div className="collectionCard" style={props.style}>
                <h1 className="value">{props.value}</h1>
                <h3 className="title">{props.title}</h3>
                <h4 className="subtitle">{props.subtitle}</h4>
                <h4 className="subtitle2">{props.subtitle2}</h4>
            </div>
        </div>
    );
}

export default CollectionCard;