import React, { useState } from 'react';
import ArticleListTable from '../components/ArticleListTable/ArticleListTable';
import NavBar from '../UI/NavBar';
import httpCall from '../Backend/HttpCall';
import styles from './Article.module.css';

const ArticleListView = () => {
  const [articleList, setArticleList] = useState([]);

  const getRandomArticles = () => {
    let randomIds = [];

    const body = {
      ids: randomIds,
    };

    for (let i = 0; i <= 30; i++) {
      randomIds.push(Math.floor(Math.random() * 5000));
    }

    httpCall('/articles', 'POST', body).then((res) => {
      if (res.data?.items[0] !== []) {
        // TODO: only add unique array items
        const newArr = [...res?.items, ...articleList];
        let objsAsStr = newArr.map((item) => JSON.stringify(item));
        const unique = [...new Set(objsAsStr)];
        const formattedSet = unique.map((item) => {
          if (typeof item === 'string') {
            return JSON.parse(item);
          }
          return item;
        });
        setArticleList(formattedSet);
      }
    });
  };

  const handleClearArticleList = () => {
    setArticleList([]);
  };

  console.log(articleList);
  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <button
          className={styles.randomArticlesButton}
          onClick={getRandomArticles}
        >
          Get random set of articles
        </button>
        <button
          className={styles.randomArticlesButton}
          onClick={handleClearArticleList}
        >
          Clear article list
        </button>
        <ArticleListTable articles={articleList} />
      </div>
    </>
  );
};

export default ArticleListView;
