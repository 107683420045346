
let store;

export const initialiseQuick = _store => store = _store;

export default (actionCreator) => (...rawArgs) => {
	let action = actionCreator(...rawArgs);
	// We copy the object so the "dispatch" property doesn't get passed to the reducer,
	// and any native "dispatch" property will pass through correctly.
	let dispatchedAction = Object.assign({}, action);
	// This also lets us use these like normal actions, except if we do use them normally the
	// new dispatch property will be present during reduction.
	action.dispatch = () => store.dispatch(dispatchedAction);
	return action;
}