import React, { useState } from 'react';
import styles from './styles.module.css';
import Button from '../Button';
import httpCall from '../../Backend/HttpCall';

const SearchBar = ({
  setArticleList,
  searchType,
  setSearchType,
  page,
  per,
}) => {
  const [searchIdentifier, setSearchIdentifier] = useState('');
  const [multiIds, setMultiIds] = useState('');

  const handleIdentifierChange = (e) => {
    e.preventDefault();
    setSearchIdentifier(e.target.value);
  };

  const handleIdentifierTypeChange = (e) => {
    e.preventDefault();
    setSearchType(e.target.value);
    setSearchIdentifier('');
  };

  const handleSearch = () => {
    if (searchIdentifier) {
      if (searchType === 'id') {
        httpCall(`/article/${searchIdentifier}`)
          .then((res) => setArticleList([res]))
          .catch((err) => console.log(err));
      } else if (searchType === 'doi') {
        httpCall(`/article/${searchIdentifier.replace('/', '%2F')}`)
          .then((res) => setArticleList([res]))
          .catch((err) => console.log(err));
      } else if (searchType === 'keyword') {
        httpCall(
          `/search?query=${searchIdentifier.replaceAll(
            ' ',
            '%20'
          )}&page=${page}&per=${per}`
        )
          .then((res) =>
            httpCall('/articles', 'POST', {
              ids: res?.results,
            }).then((res) => setArticleList(res?.items))
          )
          .catch((err) => console.log(err));
      }
    } else if (multiIds && searchType === 'multi') {
      httpCall('/articles', 'POST', {
        ids: multiIds.split(',').map((num) => parseInt(num)),
      })
        .then((res) => setArticleList(res?.items))
        .catch((err) => console.log(err));
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      {searchType !== 'paginated' && searchType !== 'multi' && (
        <>
          {' '}
          <input
            className={styles.searchBar}
            placeholder={`Search articles by ${searchType}`}
            value={searchIdentifier}
            onChange={handleIdentifierChange}
            type='search'
            autoFocus
            required
          />
        </>
      )}
      {searchType === 'multi' && (
        <textarea
          rows={4}
          cols={50}
          value={multiIds}
          onChange={(e) => {
            e.preventDefault();
            setMultiIds(e.target.value);
          }}
          placeholder='Add IDs seperated by a comma ","'
        />
      )}
      <Button customStyles={styles.searchButton} onClick={handleSearch}>
        Search
      </Button>{' '}
      <label>Search By: </label>
      <select value={searchType} onChange={handleIdentifierTypeChange}>
        <option value='paginated'>Paginated</option>
        <option value='id'>ID</option>
        <option value='doi'>doi</option>
        <option value='keyword'>Keyword Search</option>
        <option value='multi'>Multiple ID's</option>
      </select>
    </div>
  );
};

export default SearchBar;
