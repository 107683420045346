import { Environment } from "../Backend/Environment";
import GenericReducer from "./Utils/ReduxGeneric";
import quick from "./Utils/ReduxQuick";

const g = new GenericReducer("environment", {
    selectedEnvironmentName: Environment.alpha.type,
    selectedEnvironmentURL: Environment.alpha.url
}, quick);

export const setSelectedEnvironment = g.child("selectedEnvironmentName").allow("set");
export const setSelectedEnvironmentURL = g.child("selectedEnvironmentURL").allow("set");

export default g.apply();