import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { environment } from '../Backend/Environment';
import {
  setSelectedEnvironment,
  setSelectedEnvironmentURL,
} from '../Redux/Environment';
import NavBar from '../UI/NavBar';

const mapDispatchToProps = (dispatch) => ({
  environment: (newEnv) => dispatch(setSelectedEnvironment(newEnv)),
  environmentURL: (newEnv) => dispatch(setSelectedEnvironmentURL(newEnv)),
});

const AppView = (props) => {
  const [env, setEnv] = React.useState('production');

  const chooseAppEnvironment = () => {
    const environ = environment(env);
    console.log(`Trying to save environment ${env}`);
    if (environ) {
      props.environment(environ.type);
      props.environmentURL(environ.url);
      console.log(`Saved environment: ${environ.type}`);
    }
  };
  return (
    <React.Fragment>
      <NavBar />
      <div
        style={{
          margin: '25px',
        }}
      >
        <h1>Welcome to LitmapsWeb</h1>
        <label for='env'>Environment to Inspect: </label>
        <select
          id='env'
          name='Environment Selector'
          onChange={(value) => {
            console.log(value.target.value);
            setEnv(value.target.value);
          }}
        >
          <option defaultValue value='production'>
            Production
          </option>
          <option value='alpha'>Staging</option>
          <option value='local'>Local</option>
        </select>
        <br />
        <br />
        <button title='Save Environment' onClick={chooseAppEnvironment()}>
          Save Environment
        </button>
        <br />
        <br />
        <Link to='/metrics'>Metrics</Link>
        <br />
        <Link to='/articles'>Articles</Link>
      </div>
    </React.Fragment>
  );
};

export default connect(null, mapDispatchToProps)(AppView);
