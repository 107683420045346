import GenericReducer from "./Utils/ReduxGeneric";
import quick from "./Utils/ReduxQuick";

const g = new GenericReducer("user", {
	signedIn: false,
	name: "",
	email: "",
}, quick);

export const setName = g.child("name").allow("set");
export const setEmail = g.child("email").allow("set");

export const setSignedIn = g.child("signedIn").allow("set");

export default g.apply();