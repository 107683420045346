import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArticleListTable from '../components/ArticleListTable/ArticleListTable';
import SearchBar from '../components/SearchBar';
import NavBar from '../UI/NavBar';
import httpCall from '../Backend/HttpCall';
import styles from './Article.module.css';

const ArticleListView = () => {
  const [articleList, setArticleList] = useState([]);
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(15);
  const [searchType, setSearchType] = useState('paginated');

  useEffect(() => {
    if (page && per && searchType === 'paginated') {
      httpCall(`/articles?page=${page}&per=${per}`).then((res) =>
        setArticleList(res.items || [])
      );
    }
  }, [page, per, articleList.length, searchType]);

  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <Link
          style={{
            background: '#60a5fa',
            padding: '8px',
            borderRadius: '10px',
            textAlign: 'center',
            margin: '1rem auto',
            position: 'relative',
            right: '10px',
          }}
          to='/random-articles'
        >
          View random subset of articles
        </Link>
        <SearchBar
          setArticleList={setArticleList}
          setSearchType={setSearchType}
          searchType={searchType}
          page={page}
          per={per}
        />
        <ArticleListTable articles={articleList} />
      </div>
      <footer className={styles.footer}>
        <label className={styles.footerItem}>Page</label>
        <input
          className={styles.footerItem}
          type='number'
          value={page}
          onChange={(e) => {
            e.preventDefault();
            setPage(e.target.value);
          }}
        />
        <label className={styles.footerItem}>Results per page</label>
        <input
          className={styles.footerItem}
          type='number'
          value={per}
          onChange={(e) => {
            e.preventDefault();
            setPer(e.target.value);
          }}
        />
      </footer>
    </>
  );
};

export default ArticleListView;
