let cache = {};

const getIcon = async (name, alt="", subset="icon") => {
	let cacheKey = `${subset}__${name}__${alt}`;
	if(cache[cacheKey]){
		return cache[cacheKey];
	}
	let text = await new Promise((resolve, reject) => {
		let page = require(`./files/${subset}/${name}.svg`);
		fetch(page)
		.then(response => resolve(response.text()))
	});
	cache[cacheKey] = ({color="#FFFFFF", width=48, height=48}) => text
															.split(`fill="#000000"`)
															.join(`fill="${color}"`)
															.replace(`<title>${subset}/${name}</title>`, `<title>${alt}</title>`)
															.replace(`width="128px"`, `width="${width}px"`)
															.replace(`height="128px"`, `height="${height}px"`);

	return cache[cacheKey];
};

export default getIcon;