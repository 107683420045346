import https from 'https';
import http from 'http';
import store from '../Redux/index';

const responseEncoding = 'utf8';
let tokenRetries = 0;

let signedIn = false;

let authCallbacks, getAccessToken, refreshUserSession;

export const connectToAuth = (authMethods) => {
	authCallbacks = authMethods.authCallbacks;
	getAccessToken = authMethods.getAccessToken;
	refreshUserSession = authMethods.refreshUserSession;
	authCallbacks.on({
		signedOut: () => signedIn = false,
		signedIn: () => signedIn = true,
	});
}

const httpCall = (path, method="GET", postArgs=null) => {
	console.log("Calling", path, method, postArgs);
	let envName = store.getState().environment.selectedEnvironmentName;
	let envURL = store.getState().environment.selectedEnvironmentURL;
	return new Promise(async (resolve, reject) => {
		let token = await getAccessToken();
		console.log({token});
		console.log({path});
		if(!token){
			reject("not-signed-in");
			return;
		}
		if (envName !== 'local') {
			const httpOptions = {
				hostname: `${envURL}`,
				path,
				method,
				headers: {
					"User-Agent": `node ${process.version}`,
					"Content-Type": "application/json; charset=utf-8",
					"Authorization": `Bearer ${token}`
				}
			};
			const request = https.request(httpOptions, (res) => {
				let responseBufs = [];
				let responseStr = '';
				
				res.on('data', (chunk) => {
					if (Buffer.isBuffer(chunk)) {
						responseBufs.push(chunk);
					} else {
						responseStr += chunk;            
					}
				}).on('end', async () => {
					responseStr = responseBufs.length > 0 ? 
						Buffer.concat(responseBufs).toString(responseEncoding) : responseStr;
					
					let { statusCode, headers } = res;
					switch(statusCode){
						case 200:
							tokenRetries = 0;
							if(responseStr.length > 0){
								let ret = JSON.parse(responseStr);
								console.log(path, method, postArgs, {ret});
								resolve(ret);
							} else {
								resolve();
							}
						break;
						case 401:
							if(!signedIn){
								reject(new Error("not-signed-in"));
								return;
							}
							console.log({statusCode, headers, responseStr});
							console.log("Refreshing access token");
							await refreshUserSession();
							console.log("User token updated");
							tokenRetries++;
							if(tokenRetries > 2){
								reject(new Error(`auth-token-error`));
							} else {
								console.log("Retrying...");
								httpCall(path, method, postArgs).then(resolve).catch(reject);
							}
						break;
						case 500:
						{
							console.error(`Got a 500 during httpCall: ${responseStr}`);
							let obj;
							try {
								obj = JSON.parse(responseStr);
							} catch (error) {
								reject(new Error(`Unparseable 500 error: ${responseStr}`));
								return;
							}
							switch(obj.reason){
								case "sorry, too many clients already":
									reject(new Error("server-too-busy"));
								break;
								default: 
									reject(new Error("unknown"));
								break;
							}
							break;
						}
						default:
							console.log(`Unhandled error response for ${path}, ${method}:`, {postArgs, statusCode, headers, responseStr});
							reject(new Error("unknown"));
						break;
					}
				});
			})
			.on('error', (error) => {
				reject(error);
			});
			request.write(postArgs ? JSON.stringify(postArgs) : "");
			request.end();
		} else {
			console.log(path);
			const httpOptions = {
				hostname: "localhost",
				port: 8081,
				path,
				method,
				headers: {
					"User-Agent": `node ${process.version}`,
					"Content-Type": "application/json; charset=utf-8",
					"Authorization": `Bearer ${token}`
				}
			};
			console.log("Using HTTP due to local configuration.")
			const request = http.request(httpOptions, (res) => {
				let responseBufs = [];
				let responseStr = '';
				
				res.on('data', (chunk) => {
					if (Buffer.isBuffer(chunk)) {
						responseBufs.push(chunk);
					} else {
						responseStr += chunk;            
					}
				}).on('end', async () => {
					responseStr = responseBufs.length > 0 ? 
						Buffer.concat(responseBufs).toString(responseEncoding) : responseStr;
					
					let { statusCode, headers } = res;
					switch(statusCode){
						case 200:
							tokenRetries = 0;
							if(responseStr.length > 0){
								let ret = JSON.parse(responseStr);
								console.log(path, method, postArgs, {ret});
								resolve(ret);
							} else {
								resolve();
							}
						break;
						case 401:
							if(!signedIn){
								reject(new Error("not-signed-in"));
								return;
							}
							console.log({statusCode, headers, responseStr});
							console.log("Refreshing access token");
							await refreshUserSession();
							console.log("User token updated");
							tokenRetries++;
							if(tokenRetries > 2){
								reject(new Error(`auth-token-error`));
							} else {
								console.log("Retrying...");
								httpCall(path, method, postArgs).then(resolve).catch(reject);
							}
						break;
						case 500:
						{
							console.error(`Got a 500 during httpCall: ${responseStr}`);
							let obj;
							try {
								obj = JSON.parse(responseStr);
							} catch (error) {
								reject(new Error(`Unparseable 500 error: ${responseStr}`));
								return;
							}
							switch(obj.reason){
								case "sorry, too many clients already":
									reject(new Error("server-too-busy"));
								break;
								default: 
									reject(new Error("unknown"));
								break;
							}
							break;
						}
						default:
							console.log(`Unhandled error response for ${path}, ${method}:`, {postArgs, statusCode, headers, responseStr});
							reject(new Error("unknown"));
						break;
					}
				});
			})
			.on('error', (error) => {
				reject(error);
			});
			request.write(postArgs ? JSON.stringify(postArgs) : "");
			request.end();
		}
	});
};

export default httpCall;