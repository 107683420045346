import React, { useState, useEffect, useCallback } from 'react';
import NavBar from '../UI/NavBar';
import httpCall from '../Backend/HttpCall';
import Button from '../components/Button';

const ArticleView = ({ match }) => {
  const [title, setTitle] = useState('');
  const [publicationTitle, setPublicationTitle] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [doctype, setDoctype] = useState('');
  const [abstract, setAbstract] = useState('');
  const [doi, setDoi] = useState('');
  const [publicationDate, setPublicationDate] = useState('');
  const [forwardEdgeCount, setForwardEdgeCount] = useState('');
  const [backwardEdges, setBackwardEdges] = useState('');
  const [url, setUrl] = useState('');
  const [articleId, setArticleId] = useState('');
  const [forwardEdges, setForwardEdges] = useState('');
  const [backwardEdgeCount, setBackwardEdgeCount] = useState('');
  const [authorString, setAuthorString] = useState('');

  const { id } = match?.params;

  const fetchArticleData = useCallback(() => {
    httpCall(`/article/${id}`).then((data) => {
      setTitle(data.title || '');
      setPublicationTitle(data.publicationTitle || '');
      setCreatedAt(data.createdAt || '');
      setDoctype(data.doctype || '');
      setAbstract(data.abstract || '');
      setDoi(data.doi || '');
      setPublicationDate(data.publicationDate || '');
      setForwardEdgeCount(data.forwardEdgeCount || '');
      setBackwardEdges(data.backwardEdges || '');
      setUrl(data.url || '');
      setArticleId(data.id || '');
      setForwardEdges(data.forwardEdges || '');
      setBackwardEdgeCount(data.backwardEdgeCount || '');
      setAuthorString(data.authorString || '');
    });
  }, [id]);

  useEffect(() => {
    fetchArticleData();
  }, [id, fetchArticleData]);

  const customItemStyles = {
    padding: '0.75rem 0',
    lineHeight: '1.6',
  };

  const cleanObj = (obj) => {
    for (let propName in obj) {
      if (
        obj[propName] === '' ||
        obj[propName] === null ||
        obj[propName] === undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleSaveChanges = () => {
    const args = {
      title,
      publicationTitle,
      publicationDate,
      createdAt,
      doctype,
      abstract,
      doi,
      url,
      id: articleId,
      authorString, //TODO: update authorString to author
    };
    const cleanArgs = cleanObj(args);
    httpCall(`/article/${id}`, 'PUT', cleanArgs)
      .then(fetchArticleData())
      .catch((err) => console.error(err));
  };

  // TODO: style atricle view
  return (
    <>
      <NavBar />
      <div
        style={{
          width: '60%',
          display: 'block',
          textAlign: 'left',
          margin: 'auto',
        }}
      >
        <h3>
          Title:{' '}
          <textarea
            value={title}
            onChange={(e) => {
              e.preventDefault();
              setTitle(e.target.value);
            }}
          />
        </h3>
        {
          <h4>
            Publication Title:{' '}
            <textarea
              value={publicationTitle}
              onChange={(e) => {
                e.preventDefault();
                setPublicationTitle(e.target.value);
              }}
            />
          </h4>
        }
        {
          <div style={customItemStyles}>
            Created at:{' '}
            <textarea
              value={createdAt}
              onChange={(e) => {
                e.preventDefault();
                setCreatedAt(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            DocType:{' '}
            <textarea
              value={doctype}
              onChange={(e) => {
                e.preventDefault();
                setDoctype(e.target.value);
              }}
            />
          </div>
        }
        {
          <p>
            Abstract:{' '}
            <textarea
              value={abstract}
              onChange={(e) => {
                e.preventDefault();
                setAbstract(e.target.value);
              }}
            />
          </p>
        }
        {
          <div style={customItemStyles}>
            doi:{' '}
            <textarea
              value={doi}
              onChange={(e) => {
                e.preventDefault();
                setDoi(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Publication Date:{' '}
            <textarea
              value={publicationDate}
              onChange={(e) => {
                e.preventDefault();
                setPublicationDate(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Backward edges:{' '}
            <textarea
              value={backwardEdges}
              onChange={(e) => {
                e.preventDefault();
                setBackwardEdges(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Backward edge count:{' '}
            <textarea
              value={backwardEdgeCount}
              onChange={(e) => {
                e.preventDefault();
                setBackwardEdgeCount(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Forward edges:{' '}
            <textarea
              value={forwardEdges}
              onChange={(e) => {
                e.preventDefault();
                setForwardEdges(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Forward edge count:{' '}
            <textarea
              value={forwardEdgeCount}
              onChange={(e) => {
                e.preventDefault();
                setForwardEdgeCount(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            url:{' '}
            <textarea
              value={url}
              onChange={(e) => {
                e.preventDefault();
                setUrl(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            ID:{' '}
            <textarea
              value={articleId}
              onChange={(e) => {
                e.preventDefault();
                setArticleId(e.target.value);
              }}
            />
          </div>
        }
        {
          <div style={customItemStyles}>
            Authors:{' '}
            <textarea
              value={authorString}
              onChange={(e) => {
                e.preventDefault();
                setAuthorString(e.target.value);
              }}
            />
          </div>
        }
        <Button onClick={handleSaveChanges}>Save changes</Button>
      </div>
    </>
  );
};

export default ArticleView;
