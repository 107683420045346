import React from "react";
import InlineSVG from "svg-inline-react";
import getIcon from "./GetIcon";
import Mouseable from "./../Mouseable";

class Icon extends React.Component {

	state = {
		icon: null,
		pixelWidth: 1,
		pixelHeight: 1
	};

	componentDidMount(){
		this.update();
	}

	componentWillUnmount(){
		if(this.unsub){
			this.unsub();
		}
	}

	update(props=this.props){
		this.updates = (this.updates || 0) + 1;
		if(this.updates > 200){
			console.log(`Icon ${props.icon} has updated a lot. Please make sure this isn't being hammered, this is an extensive op.`);
		}
		if(this.updates > 50000){
			console.log(`Icon ${props.icon} updates way too high - aborting to avoid likely crash.`);
			return;
		}
		let { icon, color, alt } = props;
		let { pixelWidth, pixelHeight } = this.state;
		let subset = "icon";
		if(icon.includes("/")){
			let components = icon.split("/");
			subset = components[0];
			icon = components[1];
		}
		let promise = getIcon(icon, alt, subset)
		this.currentPromise = promise;
		let shouldUpdate = true;
		this.unsub = () => shouldUpdate = false;
		promise.then(iconFunction => {
			this.unsub = null;
			if(this.currentPromise === promise && shouldUpdate){
				this.setState({
					icon: iconFunction({
						color,
						width: pixelWidth,
						height: pixelHeight
					})
				});
			}
		});
	}

	componentDidUpdate(){
		if(this.lastIcon !== this.props.icon || this.lastColor !== this.props.color || this.lastAlt !== this.props.alt){
			this.lastIcon = this.props.icon;
			this.lastAlt = this.props.alt;
			this.lastColor = this.props.color;
			this.update();
		}
		let { pixelWidth, pixelHeight } = this.state;
		if(this.containerRef){
			let { clientWidth, clientHeight } = this.containerRef;
			if(clientWidth !== pixelWidth || clientHeight !== pixelHeight){
				this.setState({
					pixelWidth: clientWidth,
					pixelHeight: clientHeight
				}, () => this.update());
				
			}
		}
	}

	render(){
		let { style, onClick, heldStyle, alt } = this.props;
		let { icon } = this.state;
		if(!icon){
			return null;
		}
		return <Mouseable
				onRef={ref => this.containerRef = ref}
				style={Object.assign({}, {
					position: "relative", width: "20px", height: "20px",
					cursor: onClick ? "pointer" : null
				}, style)}
				onClick={onClick}
				heldStyle={heldStyle}
			>
			<InlineSVG raw style={{
				position: "absolute",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)"
			}} src={icon} alt={alt}/>
		</Mouseable>
	}
}

Icon.defaultProps = {
	color: "#000000",
	alt: "",
}

export default Icon;