import React from "react";
import logo from "./../assets/LitmapsLogo_White.svg";
import { Mouseable, Icon } from ".";
import { connect } from "react-redux";
import { signOut } from "../Backend/Auth";
import { Link } from "react-router-dom";

const mapStateToProps = state => ({
	signedIn: state.user.signedIn,
	email: state.user.email
});

class NavBar extends React.Component {
	state = {
		email: "jeff@litmaps.com"
	}

	onLogoutClicked() {
		signOut();
	}

	render() {
		let { onHomePressed, signedIn, email } = this.props;
		return <div style={{
			width: "100%",
			backgroundColor: "#000000",
			position: "relative",
			height: "60px",
			flex: "none",
		}}>
			<Mouseable style={{
					position: "absolute",
					top: "53%",
					transform: "translateY(-50%)",
					left: "12px",
					width: "fit-content",
					cursor: "pointer"
				}}
				hoveredStyle={{opacity: 0.8}}
				onClick={onHomePressed}
			>
				<Link to="/">
					<img src={logo} style={{
						height: "35px",
						margin: "12px"
					}} alt="Litmaps Logo" />
				</Link>
			</Mouseable>
			{
				signedIn ? <div style={{
					position: "absolute",
					top: "0px",
					right: "12px",
					height: "100%",
					width: 'auto',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center'
				}}>
					<p style={{
						color: "white",
						fontSize: "14px",
						paddingRight: "10px"
					}}>{email}</p>
					<Link to="/signIn">
						<button onClick={() => {this.onLogoutClicked()}}>Log out</button>
					</Link>
				</div> : null
			}
		</div>
	}
}

export default connect(mapStateToProps)(NavBar);