import httpCall from "./HttpCall";

export const fetchUserActivity = async (object) => {
	let result = await httpCall(`/internal/analytics?eventName=${object.eventName}&daysBeforeNow=${object.daysBeforeNow}`);
	return result;
}

export const fetchUserCount = async (object) => {
	let result = await httpCall(`/internal/analytics/userCount?daysBeforeNow=${object.daysBeforeNow}`);
	return result;
}