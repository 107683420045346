import React from "react";
import Icon from "./Icon";
import Mouseable from "./Mouseable";
import asPropsResponder from "./../Utils/PropsResponder";
import FuzzySearch from 'fuzzy-search';

class Input extends React.Component {

	state = {
		focus: false,
		hoveredSuggestionIndex: -1,
		highlightedSuggestionIndex: -1,
		suggestionResults: [],
		preferredWidth: 1,
		preferredHeight: 1,
	};

	onPropsChange(propName, props){
		switch(propName){
			case "focus":
				let { focus } = props;
				if(focus && this.inputRef){
					this.inputRef.focus();
					this.setState({focus: true});
				}
			break;
			case "suggestions":
				let { suggestions } = this.props;
				this.suggestionSearcher = suggestions ? new FuzzySearch(
					suggestions.map(s => ({s})),
					["s"],
					{sort: true}
				) : null;
				this.rebuildSuggestions();
			break;
			case "value":
				this.rebuildSuggestions(props);
			break;
			default:
			break;
		}
	}

	rebuildSuggestions = (props=this.props) => {
		if(this.suggestionSearcher){
			let suggestionResults = this.suggestionSearcher.search(props.value);
			this.setState({ suggestionResults, highlightedSuggestionIndex: -1 });
		}
	}

	componentDidUpdate(){
		let { preferredWidth, preferredHeight } = this.state;
		let { width, height } = this.sizeRef.getBoundingClientRect();
		if(width !== preferredWidth || height !== preferredHeight){
			this.setState({
				preferredWidth: width,
				preferredHeight: height,
			});
		}
	}

	render(){
		let {
			onChange, onSubmit,
			rightButton, placeholder, style, disableSubmit, type, disabled,
			suggestionsName, onRemoveSuggestion, value, minHeight, maxHeight, wrap
		} = this.props;
		let {
			suggestionResults,
			focus,
			highlightedSuggestionIndex,
			hoveredSuggestionIndex,
			preferredHeight
		} = this.state;
		let rightButtonWidth = 48;
		suggestionResults = suggestionResults.slice(0, Math.min(suggestionResults.length, 5))
		suggestionResults = suggestionResults.filter(sr => sr.s !== value);
		let InputComponent = wrap ? "textarea" : "input";
		return <div style={Object.assign({
			position: "relative",
		}, style)}>
			<div style={{
				position: "relative",
				borderRadius: "6px",
				overflow: "hidden",
				border: "1px solid #707070",
				paddingRight: rightButton ? `${rightButtonWidth}px` : null,
			}}>
				<p ref={ref => this.sizeRef = ref} style={{
					margin: "0px",
					position: "absolute",
					top: "0px", left: "0px", right: rightButton ? `${rightButtonWidth}px` : "0px",
					opacity: 0,
					pointerEvents: "none",
					fontSize: "14px",
					padding: "6px",
					paddingLeft: "12px",
				}}>{value || "_"}</p>
				<InputComponent
					ref={ref => this.inputRef = ref}
					type={type}
					style={{
						width: "100%",
						minHeight: wrap ? minHeight : null,
						maxHeight: wrap ? maxHeight : null,
						height: wrap ? `${preferredHeight}px` : null,
						fontSize: "14px",
						padding: "6px",
						paddingLeft: "12px",
						backgroundColor: disabled ? "#eee" : "#fff",
					}}
					onFocus={() => this.setState({focus: true})}
					onBlur={() => this.setState({
							focus: false,
							highlightedSuggestionIndex: -1
						})
					}
					disabled={disabled}
					value={value}
					onChange={evt => onChange(evt.target.value)}
					onKeyDown={evt => {
						if(!disabled && !disableSubmit && onSubmit && evt.key === "Enter"){
							if(highlightedSuggestionIndex === -1){
								onSubmit();
								this.inputRef.blur();
							} else {
								if(onChange){
									onChange(suggestionResults[highlightedSuggestionIndex].s);
								}
								this.setState({
									highlightedSuggestionIndex: -1,
								});
							}
						}
						if(!disabled && evt.key === "ArrowDown" && suggestionResults.length - 1 > highlightedSuggestionIndex){
							evt.preventDefault();
							this.setState({
								highlightedSuggestionIndex: highlightedSuggestionIndex + 1
							});
						}
						if(!disabled && evt.key === "ArrowUp" && highlightedSuggestionIndex > -1){
							evt.preventDefault();
							this.setState({
								highlightedSuggestionIndex: highlightedSuggestionIndex - 1
							});
						}
						if(evt.key === "Escape"){
							if(suggestionResults.length > 0){
								this.setState({suggestionResults: []});
							} else {
								this.inputRef.blur();
							}
						}
					}}
				/>
				{
					rightButton ? <Mouseable
						style={{
							backgroundColor: disabled || disableSubmit ? "#ccc" : "#2D2D3E",
							position: "absolute",
							top: "0px",
							right: "0px",
							width: `${rightButtonWidth}px`,
							height: "100%",
							cursor: disabled || disableSubmit ? null : "pointer",
						}}
						hoveredStyle={{
							backgroundColor: "#464672"
						}}
						heldStyle={{
							backgroundColor: "#76A5FF"	
						}}
						onClick={() => {
							if(!disabled && !disableSubmit && onSubmit){
								onSubmit();	
							}
						}}
					>
						<Icon
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: "28px",
								height: "28px"
							}}
							icon={rightButton}
							color="white"
						/>
					</Mouseable> : null
				}
				{
					placeholder && !value ? <p style={{
						position: "absolute",
						pointerEvents: "none",
						color: "#666",
						marginTop: "0px",
						top: "6px",
						left: "12px",
						fontSize: "14px",
						fontWeight: "400",
						fontStyle: "italic",
					}}>{placeholder}</p> : null
				}
			</div>
			{
				suggestionResults.length && focus && value.length > 0 && !disabled > 0 ? <div
					style={{
						position: "absolute",
						top: "100%",
						width: "100%",
						zIndex: 1,
						backgroundColor: "#efefef",
						paddingTop: "6px",
						boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
					}}
					onMouseLeave={() => this.setState({hoveredSuggestionIndex: -1})}
				>
					<p style={{
						margin: "2px 6px",
						fontWeight: "700",
						fontSize: "10px",
					}}>{suggestionsName}</p>
					{
						suggestionResults.map((sr, i) => <Mouseable
							key={i}
							style={{
								backgroundColor: i === highlightedSuggestionIndex ? "#76A5FF" : null,
								cursor: "pointer",
								padding: "6px",
								position: "relative",
							}}
							hoveredStyle={{
								backgroundColor: "#76A5FF"
							}}
							onMouseEnter={() => {
								this.setState({
									highlightedSuggestionIndex: -1,
									hoveredSuggestionIndex: i,
								});
							}}
							onMouseDown={evt => {
								evt.preventDefault();
								if(onChange){
									onChange(sr.s);	
								}
							}}
						>
							<p style={{margin: "0px", marginRight: onRemoveSuggestion ? "24px" : null}}>"{sr.s}"</p>
							{
								onRemoveSuggestion && hoveredSuggestionIndex === i ? <Mouseable
									style={{
										position: "absolute",
										top: "50%",
										right: "6px",
										transform: "translateY(-50%)",
									}}
									onMouseDown={evt => {
										evt.preventDefault();
										evt.stopPropagation();
										onRemoveSuggestion(sr.s, i);
									}}
								>
									<Icon
										style={{
											width: "12px",
											height: "12px",
										}}
										alt={`Remove from ${suggestionsName}`}
										icon="cross"
									/>
								</Mouseable> : null
							}
							
						</Mouseable>)
					}
				</div> : null
			}
		</div>
	}

}

Input.defaultProps = {
	value: "",
	rightButtonIcon: null,
	wrap: false,
}

export default asPropsResponder(Input, ["focus", "suggestions", "value"], { onMount: true });