import React from "react";

const asPropsResponder = (Wrapped, listenTo, options={}) => {

	if(typeof(listenTo) === "string"){
		listenTo = [listenTo];
	}

	return class extends React.Component {

		lastProps = {};

		componentDidMount(){
			if(typeof(this.childRef.onPropsChange) !== "function" ){
				throw new Error(`PropsResponder fails to find onPropsChange function in ${Wrapped.name}`);
			}
			this.lastProps = Object.assign({}, this.props);

			if(options.onMount){
				let p = Object.assign({}, Wrapped.defaultProps, this.props);
				for(let i = 0; i<listenTo.length; i++){
					this.childRef.onPropsChange(listenTo[i], p, {});
				}
			}
		}

		componentDidUpdate(){
			let p = Object.assign({}, Wrapped.defaultProps, this.props);
			for(let i = 0; i<listenTo.length; i++){
				let key = listenTo[i];
				let oldValue = this.lastProps[key];
				let newValue = p[key];
				if(oldValue !== newValue){
					this.childRef.onPropsChange(key, p, this.lastProps);
				}
				this.lastProps[key] = p[key];
			}
		}

		render(){
			return <Wrapped ref={ref => this.childRef = ref} {...this.props} />
		}

	}
}

export default asPropsResponder;