import Amplify, { Auth } from 'aws-amplify';
import { Hub } from "@aws-amplify/core";
import jwt_decode from "jwt-decode";
import { amplifyConfig, oauthConfig } from "./amplifyConfig";
import Callbacks from "../Utils/Callbacks";
import httpCall, { connectToAuth } from './HttpCall';

let tokens = null;

let deletableAccount = false;

const setTokensFromSession = session => {
    tokens = {
        idToken: session.idToken.getJwtToken(),
        accessToken: session.accessToken.getJwtToken(),
        refreshToken: session.refreshToken.getToken()
    };
}

const getTokens = async () => {
    try {
        if(tokens){
            return tokens;
        } else {
            if(await Auth.currentAuthenticatedUser()){
                const currentSession = await Auth.currentSession();
                setTokensFromSession(currentSession);
            } else {
                console.log("Can't get tokens, not signed in.");
            }
        }
    } catch (error) {
        console.log("getTokens failed - we may not be signed in.");
    } finally {
        return tokens;
    }
}

export const authCallbacks = new Callbacks();

export const getAccessToken = async () => {
    let t = await getTokens();
    if(t){
        return t.accessToken;   
    } else {
        return null
    }
};

export const getIdToken = async () => {
    let t = await getTokens();
    if(t){
        return t.idToken;   
    } else {
        return null
    }
};

const tokenField = async (token, fieldName) => {
    let t = await getTokens();
    if(t){
        return jwt_decode(t[token])[fieldName];
    }
    return null;
}

const GOOGLE_IDENTIFIER = "google";

export const getCurrentUserEmail = () => tokenField("idToken", "email");
export const getCurrentUserName = () => tokenField("idToken", "name");

let signingIn = false;
export const initialiseAuth = () => {
	// aws-amplify configuration
    Amplify.configure({Auth: amplifyConfig});
    Auth.configure({oauth: oauthConfig});
    Hub.listen('auth', async data => {
        console.log("Hub event: ", data.payload);
        switch (data.payload.event) {
            case 'signOut':
                authCallbacks.dispatch("signedOut");
                signingIn = false;
            break;
            case 'signIn':
                console.log("Sign in", data.payload.data);
                signingIn = true;
                if(!signingIn){
                    console.log("Sign in aborted while polling for token");
                } else {
                    console.log("Done. And done.");
                    maintainAccount(data.payload.data);
                    authCallbacks.dispatch("signedIn");   
                }
                signingIn = false;
                break;
            case 'signIn_failure':
                console.error(`Sign in failure`, data.payload.data);
                signingIn = false;
                break;
            default:
                break;
        }
    });
    Auth.currentAuthenticatedUser().then(data => {
        console.log("Initial auth: ", data);
        maintainAccount(data);
        authCallbacks.dispatch("signedIn");
    }).catch(error => {
        console.error(`No initial auth: `, error);
        authCallbacks.dispatch("signedOut");
        tokens = null;
    });
}

const maintainAccount = account => {
    deletableAccount = account.username.substring(0, GOOGLE_IDENTIFIER.length) !== GOOGLE_IDENTIFIER;
}

/* Sign in */

export const signIn = async (email, password) => {
    try {
        console.log("Signing in...");
        await Auth.signIn(email, password);
        console.log("Signed in");
        return { success: true };
    } catch (error) {
        console.error(`Error with signIn: ${error.message}`);
        if (error.code === "UserNotConfirmedException") {
            throw new Error("user-not-confirmed");
        } else if (error.message === "Incorrect username or password."){
            throw new Error("incorrect-password");
        } else {
            throw new Error("unknown");
        }
    }
};

export const signInOrcid = () => {
    Auth.federatedSignIn({provider: 'ORCiD'})
        .then(result => {
        	console.log("ORCiD sign in successful");
        })
        .catch(error => {
        console.error(`Error with ORCiD sign in: ${error.message}`);
    });
};

export const signInGoogle = () => {
    Auth.federatedSignIn({provider: 'Google'})
        .then(result => {
            console.log("Signed in with Google");
        })
        .catch(error => {
	        console.error(`Error signing in with Google: ${error.message}`);
    	});
}


export const confirmSignUp = (email, code) => Auth.confirmSignUp(email, code);

/* Sign out */

export const signOut = () => {
    Auth.signOut()
        .then(response => {
            console.log("signOut ", response);
            tokens = null;
        })
        .catch(error => {
        	console.error(`Error signing out: ${error.message}`);
        })
};

export const deleteAccount = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log("Deleting account...");
    await user.deleteUser();
    console.log("Done!");
}

export const accountIsDeletable = () => deletableAccount;


/* Profile stuff */


export const resendSignUpEmail = (email) => Amplify.Auth.resendSignUp(email);

// Not sure what the difference is here.
// export const resendEmailVerification = () => {
//     return dispatch => {
//         Auth.verifyCurrentUserAttribute("email")
//             .then(() => {
//                     console.log("resendCodeToVerify success");
//                 }
//             )
//             .catch((error)=>{
//                 console.log(`resendCodeToVerify fails: ${error.message}`);
//             })
//     }
// };

export const forgotPassword = ({email}) => {
    Auth.forgotPassword(email)
        .then(response => {
                console.log("awsForgotPassword succeeds", response);
            }
        )
        .catch(error => {
            console.log(`awsForgotPassword error ${error.message}`);
        });
};

export const overwriteForgottenPassword = (email, code, newPassword) => {
    Auth.forgotPasswordSubmit(email, code, newPassword)
        .then(response => {
            // auto sign-in
            console.log("awsForgotPasswordSubmit successful");
            signIn(email, newPassword);
        })
        .catch(error => {
            console.log(`awsForgotPasswordSubmit error ${error.message}`);
        })
};


export const changePassword = (oldPassword, newPassword) => {
    Auth.currentAuthenticatedUser()
        .then(response => {
                Auth.changePassword(response, oldPassword, newPassword)
                    .then(res => {
                    	console.log("changePassword all good");
                    }).catch(error => {
                    console.error(`changePassword fails: ${error.message}`);
                })
            }
        ).catch(error => {
        	console.error(`changePassword first clause error ${error.message}`);
    });
};

export const updateDetails = ({email, name}) => {
    Auth.currentAuthenticatedUser()
        .then(response => {
            Auth.updateUserAttributes(response, { email, name })
                .then(() => {
                    console.log("updateDetails all good");
                })
        })
        .catch(error => {
            console.error(`updateDetails fails, ${error.message}`);
        });
};


// unused ?
export const verifyAttributesSubmit = (code) => {
    Auth.verifyCurrentUserAttributeSubmit("email", code)
        .then(response => {
        	console.log("awsVerifyAttributesSubmit all good");
        })
        .catch(error => {
            console.error(`awsVerifyAttributesSubmit fails: ${error.message}`);
        })
};


export const signUp = async (email, password, details) => {
    await Auth.signUp({
        username: email,
        password: password,
        attributes: details
    });
    return { success: true };
}



export const refreshUserSession = async () => {
	// Not sure about the bypassCache flag here
    const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});
    const currentSession = await Auth.currentSession();
    await new Promise((resolve, reject) => {
	    cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
	    	if(err){
	    		console.error(`Error refreshing user session! ${err.message}`);
	    		reject(err);
	    	} else {
	    		console.log("Successfully refreshed user session");
	    		setTokensFromSession(session);
	    		resolve();
	    	}
	    });
    });
};



connectToAuth({ authCallbacks, getAccessToken, refreshUserSession });