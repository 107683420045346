import * as React from "react";
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {PUBLIC_ROUTES} from "./Routes/RouteConfig";
import PrivateRoute from "./Routes/PrivateRoute";
import { setName, setEmail, setSignedIn } from './Redux/User';
import { authCallbacks, getCurrentUserEmail, getCurrentUserName } from './Backend/Auth';
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => ({
  setName: (name) => dispatch(setName(name)),
  setEmail: (email) => dispatch(setEmail(email))
});

class App extends React.Component {
  	componentDidMount(){
        authCallbacks.on({
          signedIn: async () => {
            console.log("App Callback called.")
            setSignedIn(true).dispatch();
            getCurrentUserName().then(username => {this.props.setName(username)});
            getCurrentUserEmail().then(email => {this.props.setEmail(email)});
            
          },
          signedOut: () => {
            setSignedIn(false).dispatch();
          }
      });
    }

    render() {
      return <BrowserRouter basename={process.env.PUBLIC_URL}>
                <div>
                    <Switch>
                        {PUBLIC_ROUTES.map(item => (
                            <Route key={item.path}
                                  component={item.component}
                                  exact={item.exact}
                                  path={item.path}
                            />
                        ))}
                        <PrivateRoute/>
                    </Switch>
                </div>
      </BrowserRouter>
    }
}

export default connect(null, mapDispatchToProps)(App);
