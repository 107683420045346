import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const ArticleListTable = ({ articles }) => {
  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Author(s)</th>
            <th>Publication date</th>
            <th>Abstract</th>
            <th>Full details</th>
          </tr>
        </thead>
        <tbody>
          {articles &&
            articles.map((article) => {
              return (
                <tr key={article.id}>
                  <td>{article.title}</td>
                  <td>{article.authorString}</td>
                  <td>{article.publicationDate}</td>
                  <td>{article.abstract}</td>
                  <td>
                    <Link to={`/article/${article.id}`}>View</Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ArticleListTable;
