
const stateValueAtPath = (state, path, wildcards, wcArgs) => {
	for(let i = 0; i<path.length; i++){
		let p = wcArgs[wildcards[path[i]]] || path[i];
		state = state[p];
	}
	return state;
}

const injectStateValueAtPath = (state, value, path, wildcards, wcArgs) => {
	if(path.length === 0){
		return value;
	}
	let obj = Object.assign({}, state);
	let ret = obj;
	let ogRef = state;
	for(let i = 0; i<path.length; i++){
		let p = wcArgs[wildcards[path[i]]] || path[i];
		if(i === path.length - 1){
			obj[p] = value;
		} else {
			obj[p] = Object.assign({}, ogRef[p]);
			ogRef = ogRef[p];
			obj = obj[p];
		}
	}
	return ret;
}

const operations = {

	// Standard
	set: {
		actionArgs: ["value"],
		reducer: (state, action) => action.value,
	},
	update: {
		actionArgs: ["updates"],
		reducer: (state, action) => Object.assign({}, state, action.updates)
	},
	clear: {
		actionArgs: ["value", "clearValue"],
		defaultActionArgs: {
			clearValue: null
		},
		reducer: (state, action) => state === action.value ? action.clearValue : state,
	},

	// Numbers
	increment: {
		actionArgs: [],
		reducer: state => state + 1,
	},
	decrement: {
		actionArgs: [],
		reducer: state => state - 1,
	},

	// Arrays
	addElement: {
		actionArgs: ["element"],
		reducer: (state, action) => [...state, action.element]
	},
	addElements: {
		actionArgs: ["elements"],
		reducer: (state, action) => [...state, ...action.elements]
	},
	removeAtIndex: {
		actionArgs: ["index"],
		reducer: (state, action) => [
			...state.slice(0, action.index),
			...state.slice(action.index + 1),
		]
	},
	removeElement: {
		actionArgs: ["element", "compare"],
		defaultActionArgs: {
			compare: (a, b) => a === b,
		},
		reducer: (state, action) => {
			let { element, compare } = action;
			let index = state.findIndex(el => compare(element, el));
			if(index === -1){
				console.warn(`ReduxGeneric removeElement fails to find element ${element} in state array ${state} with compare ${compare}.`);
				return state;
			} else {
				return [
					...state.slice(0, index),
					...state.slice(index + 1),
				]
			}
		}
	},
	setElement: {
		actionArgs: ["index", "value"],
		reducer: (state, action) => [
			...state.slice(0, action.index),
			action.value,
			...state.slice(action.index + 1),
		]
	},
	updateElement: {
		actionArgs: ["index", "updates"],
		reducer: (state, action) => [
			...state.slice(0, action.index),
			Object.assign({}, state[action.index], action.updates),
			...state.slice(action.index + 1),
		]
	},
	addUniqueElement: {
		actionArgs: ["element", "compare"],
		defaultActionArgs: {
			compare: (a, b) => a === b,
		},
		reducer: (state, action) => state.some(el => action.compare(el, action.element)) ? state : [...state, action.element]
	},
	addUniqueElements: {
		actionArgs: ["elements", "compare"],
		defaultActionArgs: {
			compare: (a, b) => a === b,
		},
		reducer: (state, action) => [
			...state,
			...action.elements.filter(e1 => !state.some(e2 => action.compare(e1, e2)))
		]
	},

	// Maps
	setMember: {
		actionArgs: ["key", "value"],
		reducer: (state, action) => Object.assign({}, state, { [action.key]: action.value }),
	},
	updateMember: {
		actionArgs: ["key", "updates"],
		reducer: (state, action) => Object.assign({}, state, {
			[action.key]: Object.assign({}, state[action.key], action.updates)
		})
	},
	deleteMember: {
		actionArgs: ["key"],
		reducer: (state, action) => {
			let newState = Object.assign({}, state);
			delete(newState[action.key]);
			return newState;
		}
	},
	setMembers: {
		actionArgs: ["keys", "values"],
		reducer: (state, action) => {
			let newState = {};
			action.keys.forEach((k, i) => newState[k] = action.values[i]);
			return Object.assign({}, state, newState);
		},
	},
	updateMembers: {
		actionArgs: ["keys", "updates"],
		reducer: (state, action) => {
			let newState = {};
			action.keys.forEach((k, i) => newState[k] = Object.assign({}, state[k], action.updates[i]));
			return Object.assign({}, state, newState);
		},
	},
	deleteMembers: {
		actionArgs: ["keys"],
		reducer: (state, action) => {
			let newState = Object.assign({}, state);
			action.keys.forEach(k => delete(newState[k]));
			return newState;
		},
	},

}

const reservedArgs = [];
Object.values(operations).forEach(op => {
	op.actionArgs.forEach(a => {
		if(!reservedArgs.includes(a)){
			reservedArgs.push(a);
		}
	});
});

const expandPathParam = p => typeof(p) === "string" ? p.split("/") : p

export default class GenericReducer {

	constructor(reducerId, initialState={}, wrapActions){
		this.initialState = initialState;
		this.reducerId = reducerId;
		this.reducers = {};
		this.wrapActions = wrapActions;
	}

	multiple = (actionGenerators, argsTransformer) => {
		let actionType = `__REDUX-GENERIC_multiple[${actionGenerators.map(r => r.type).join(",")}]`;
		let retAction = (...rawArgs) => {
			let args = argsTransformer(...rawArgs);
			if(!Array.isArray(args)){
				throw new Error(`GenericReducer: multiple(actionGenerators) arguments did not resolve as an array.`);
			}
			if(args.length !== actionGenerators.length){
				throw new Error(`GenericReducer: multiple(actionGenerators) has incorrect number of arguments (${args.length}, expecting ${actionGenerators.length})`);
			}
			this.reducers[actionType] = (state, action) => {
				for(let i = 0; i<actionGenerators.length; i++){
					let a = actionGenerators[i](args[i]);
					state = this.reducers[a.type](state, a);
				}
				return state;
			}
			return Object.assign({}, { type: actionType }, args);
		}
		if(this.wrapActions){
			retAction = this.wrapActions(retAction);
		}
		return retAction;
	}

	child = (path) => {
		if(!path){
			path = [];
		}

		path = expandPathParam(path);
		
		return {
			child: p => this.child([...path, p]),
			allow: (operation, argsTransformer) => 
			{
				let retFunc = this._allow(operation, argsTransformer, path);
				retFunc.constrain = constraint => {
					return this._allow(operation, argsTransformer, path, { constraint });
				}
				return retFunc;
			},
		};
	}

	allow = (operation, argsTransformer) => {
		return this._allow(operation, argsTransformer, []);
	}

	_allow = (operation, argsTransformer, path, options={}) => {
		let actionType = `__REDUX-GENERIC_${this.reducerId}_${path.join("/")}_${operation}`;

		let op = operations[operation];

		let requiredArgs = op.actionArgs.slice();

		let wildcardArgsMap = {};
		for(let i = path.length-1; i>=0; i--){
			if(path[i][0] === "*"){
				let wcArg = path[i].substring(1);
				if(reservedArgs.includes(wcArg)){
					throw new Error(`GenericReducer: wildcard argument ${wcArg} is reserved. You cannot use any of [${reservedArgs.join(", ")}] as wildcard arguments.`);
				}
				if(wildcardArgsMap[path[i]]){
					throw new Error(`GenericReducer: wildcard argument ${wcArg} already exists for this path. You cannot have duplicate wildcard identifiers in the same path.`);
				}
				wildcardArgsMap[path[i]] = wcArg;
				requiredArgs.unshift(wcArg);
			}
		}

		// Default argsTransformer will expect arguments to be supplied in the same
		// order as expected by the operation. Wildcards first.
		if(!argsTransformer){
			argsTransformer = (...args) => {
				let ret = {};
				for(let i = 0; i<requiredArgs.length; i++){
					ret[requiredArgs[i]] = args[i];
				}
				return ret;
			}
		}

		this.reducers[actionType] = (state, action) => {
			let stateValue = stateValueAtPath(state, path, wildcardArgsMap, action);
			let newStateValue = op.reducer(stateValue, action);
			if(stateValue !== newStateValue && options.constraint){
				newStateValue = options.constraint(newStateValue, state);
			}
			if(stateValue === newStateValue){
				return state;
			} else {
				return injectStateValueAtPath(state, newStateValue, path, wildcardArgsMap, action);
			}
		}

		// Return a function which returns a reducer.
		// This function remaps the given arguments into arguments ReduxGeneric is expecting.
		let retAction = (...rawArgs) => {
			let args = argsTransformer(...rawArgs);

			let missing = [];
			for(let i = 0; i<requiredArgs.length; i++){
				if(!args.hasOwnProperty(requiredArgs[i]) || args[requiredArgs[i]] === undefined){
					if(op.defaultActionArgs && op.defaultActionArgs.hasOwnProperty(requiredArgs[i])){
						args[requiredArgs[i]] = op.defaultActionArgs[requiredArgs[i]];
					} else if(args[requiredArgs[i]] !== undefined) {
						missing.push(requiredArgs[i]);
					}
				}
			}
			if(missing.length > 0){
				throw new Error(`ReduxGeneric: Error calling genericAction ${this.reducerId}@[${path.join("/")}]:${operation}, missing required generic arguments [${missing.join(", ")}]`);
			}

			return Object.assign({}, { type: actionType }, args);
		}
		if(this.wrapActions){
			retAction = this.wrapActions(retAction);
		}
		return retAction;
	}

	apply = (modifiedReducer) => {
		modifiedReducer = modifiedReducer || (state => state);

		return (state, action) => {
			// Run the normal reducer first so initial state gets soaked according to the user's
			// existing implementation.
			let ret = modifiedReducer(state, action);

			// If they haven't provided a reducer to bolt onto, we can apply our initial state.
			if(!ret && this.initialState){
				ret = Object.assign({}, this.initialState);
			}
			// Run any applicable action handlers.
			if(this.reducers[action.type]){
				return this.reducers[action.type](ret, action);
			}
			return ret;
		}
	}
}